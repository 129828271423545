<template>
  <div class="qgroup-form display--flex">
    <div class="card qgroup-form__staff">
      <div class="card-header card-header-divider">Прохождение</div>
      <div class="card-body">
        <form action="#" @submit.prevent="submitPassing" novalidate>
          <div class="qgroup-form__inputs display--flex justify-content-center">
            <div class="qgroup-form__col1">
              <custom-input
                label="Код"
                disabled
                name="code"
                v-model="form.code"
              ></custom-input>
              <custom-input
                label="Статус"
                disabled
                name="status"
                v-model="form.status"
              ></custom-input>
              <custom-select-2
                label="Сотрудник"
                name="user"
                v-model="form.user_id"
                :settings="usersAjax"
              />
            </div>
          </div>
          <div
            class="
              qgroup-form__btns
              mt-25
              mb-10
              display--flex
              justify-content-end
            "
          >
            <button type="submit" class="btn btn-accent mr-10">
              Сохранить
            </button>
            <router-link to="/test/passing" class="btn btn-w"
              >Назад</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useErrors from "../../../mixins/useErrors";
import { reactive, ref } from "@vue/reactivity";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import CustomInput from "../../../components/Forms/Fields/CustomInput.vue";
import standartError from "../../../mixins/standartError";
import useAjaxSelect from "@/mixins/useAjaxSelect";

export default {
  components: { CustomSelect2, CustomInput },
  name: "passing-edit-form",
  setup() {
    const store = useStore(),
      route = useRoute(),
      { setErrors, clearCustomErrors } = useErrors();
    const form = reactive({
      status: "",
      code: "",
      user_id: null,
    });
    const statuses = {
      new: "Новый",
      payed: "Оплачен",
      instruction: "Инструкция",
      questionnaire: "Опросник",
      in_progress: "В процессе",
      finished: "Завершен",
    };
    const passing = computed(() => store.state.test.passing);
    const { usersAjax } = useAjaxSelect();

    onBeforeMount(() => {
      if (route.params.id) {
        store.dispatch("test/getPassing", route.params.id).then(() => {
          form.status = statuses[passing.value.status];
          form.code = passing.value.code;
          form.user_id = String(passing.value.user_id);
        });
      }
    });

    let submitPassing = () => {
      store
        .dispatch("test/updatePassing", 1, { ...form })
        .catch(standartError(setErrors, clearCustomErrors));
    };

    return {
      form,
      submitPassing,
      usersAjax,
      passing,
      statuses,
    };
  },
};
</script>

<style lang="scss" scoped>
.qgroup-form {
  padding: 0 0 10px 0;
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  .form-group {
    margin: 35px -15px;
  }

  .card-body {
    padding: 20px 50px;
  }

  &__col1 {
    flex: 1;
  }

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 973px) {
    .form-group {
      margin: 15px -15px;
    }
  }
}
</style>